import Entity from "@/store/entities/entity";

export class UserNotificationVm extends Entity<string>{
    date: Date;
    message: string;
    user: string;
    jobId: string;
    orderId: string;

    constructor(message: string, date: Date) {
        super();
        this.message = message;
        this.date = date;
    }
}
