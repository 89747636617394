
    import AbpBase from "../../../../lib/abpbase";
    import {Component, Prop} from "vue-property-decorator";
    import ClientRequestDocumentDto from "@/store/entities/jobcontext/clientrequestdocument";

    @Component
    export default class ClientRequestDocument extends AbpBase {
        @Prop({default: new ClientRequestDocumentDto()}) document: ClientRequestDocumentDto;
        private filename: string = null;

        async handleUpload(file) {
            this.document.file = file;
            this.filename = file.name;
            this.$emit('file-uploaded');
        }


    }
