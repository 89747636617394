
import { Component, Vue,Inject,Prop,Emit } from 'vue-property-decorator';
import AbpBase from '../../../lib/abpbase'
@Component({})
export default class SidebarMenu extends AbpBase {
    name:string='sidebarMenu';
    @Prop({type:Array}) menuList:Array<any>;
    @Prop({type:Number}) iconSize:number;
    @Prop({type:String,default:'dark'}) menuTheme:string;
    @Prop({type:Array}) openNames:Array<string>;
    itemTitle(item:any):string{
        return this.L(item.meta.title);
    }
    @Emit('on-change')
    changeMenu(active:string){
    }
    updated () {
        this.$nextTick(() => {
            if (this.$refs.sideMenu) {
                (this.$refs.sideMenu as any).updateActiveName();
            }
        });
    }

}
