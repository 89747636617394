import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Node from '../../entities/jobcontext/node'
import NodeVm from '../../entities/jobcontext/nodevm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import LookUp from "@/store/entities/lookup";
import JobTemplateNode from "@/store/entities/jobcontext/jobtemplatenode";


interface NodeState extends ListState<NodeVm> {
    editNode: Node,
}

class NodeModule extends ListModule<NodeState, any, NodeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<NodeVm>(),
        loading: false,
        editNode: new Node()
    }
    actions = {
        async getAll(context: ActionContext<NodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/node/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<NodeVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<NodeState, any>, payload: any) {
            await Ajax.post('/api/node', payload.data);
        },
        async update(context: ActionContext<NodeState, any>, payload: any) {
            await Ajax.put('/api/node', payload.data);
        },
        async delete(context: ActionContext<NodeState, any>, payload: any) {
            await Ajax.delete('api/node?Id=' + payload.data.id);
        },
        async get(context: ActionContext<NodeState, any>, payload: any) {
            let response = await Ajax.get('api/node/' + payload.data);
            return response.data.result as Node;
        },
        async fields(context: ActionContext<NodeState, any>, payload: any) {
            let response = await Ajax.get('/api/job-template/selected-nodes-data?' + payload.map(x => 'nodes=' + x.toString()).join('&'));
            return response.data.result as Array<LookUp>;
        },
        async lookUp(context: ActionContext<NodeState, any>) {
            let response = await Ajax.get('api/dictionary/node');
            return response.data.result as Array<LookUp>;
        },
        async jobTemplateNodes(context: ActionContext<NodeState, any>) {
            let response = await Ajax.get('api/node/job-template');
            return response.data.result as Array<JobTemplateNode>;
        },
        async missedFields(context: ActionContext<NodeState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/node-fields?' + payload.map(x => 'nodes=' + x.toString()).join('&'));
            return response.data.result as Array<LookUp>;
        },
        async jobNodes(context: ActionContext<NodeState, any>, payload: any){
            let response = await Ajax.get('/api/dictionary/job-node?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: NodeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: NodeState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: NodeState, node: Node) {
            state.editNode = node;
        }
    }
}

const nodeModule = new NodeModule();
export default nodeModule;
