
    import AbpBase from "../../../../lib/abpbase";
    import {Component, Vue} from "vue-property-decorator";
    import ClientRequestDocument from "@/views/setting/jobcontext/jobdocumentrequestcontext/client-requestdocument.vue";
    import ErrorPage from "@/views/pages/error.vue";
    import ClientJob from "@/store/entities/jobcontext/clientjob";
    import JobClientFormData from "@/views/setting/jobcontext/job/job-client-form-data.vue";

    @Component({
        components: {ClientRequestDocument, ErrorPage, JobClientFormData},
    })
    export default class ClientJobDocumentRequest extends AbpBase {
        private requestIdentifier: string
        private documents: any;
        private isSend: boolean = false;
        private buttonDisabled: boolean = true;
        private job: ClientJob = new ClientJob();

        fileUploaded() {
            this.buttonDisabled = false;
        }

        data() {
            return {
                documents: []
            }
        }

        async loadJob() {
            this.job = await this.$store.dispatch('jobDocumentRequest/getJob', this.requestIdentifier);
        }

        async loadDocuments() {
            this.documents = await this.$store.dispatch('jobDocumentRequest/getDocuments', this.requestIdentifier);
        }

        async mounted() {
            await this.loadDocuments();
            this.isSend = (this.documents === null || this.documents.length === 0);
            if (!this.isSend) {
                await this.loadJob();
            }
        }

        created() {
            this.requestIdentifier = this.$route.params["requestIdentifier"];
        }

        async submit() {
            let data = this.documents.filter(x => x.file !== undefined && x.file !== null);
            let formData = new FormData();
            formData.append('requestIdentifier', this.requestIdentifier);
            for (let i = 0; i < data.length; i++) {
                formData.append("documents[" + i + "].documentId", data[i].documentId)
                formData.append("documents[" + i + "].file", data[i].file)
            }
            await this.$store.dispatch({type: 'jobDocumentRequest/submitDocuments', data: formData});
            await this.loadDocuments();
        }
    }

