import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import JobTemplate from '../../entities/jobcontext/jobtemplate'
import JobTemplateVm from '../../entities/jobcontext/jobtempatevm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import CountryLookUp from "@/store/entities/countryLookUp";


interface JobTemplateState extends ListState<JobTemplateVm> {
    editJobTemplate: JobTemplate,
}

class JobTemplateModule extends ListModule<JobTemplateState, any, JobTemplateVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<JobTemplateVm>(),
        loading: false,
        editJobTemplate: new JobTemplate()
    }
    actions = {
        async getAll(context: ActionContext<JobTemplateState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/job-template/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<JobTemplateVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<JobTemplateState, any>, payload: any) {
            await Ajax.post('/api/job-template', payload.data);
        },
        async update(context: ActionContext<JobTemplateState, any>, payload: any) {
            await Ajax.put('/api/job-template', payload.data);
        },
        async delete(context: ActionContext<JobTemplateState, any>, payload: any) {
            await Ajax.delete('api/job-template?Id=' + payload.data.id);
        },
        async get(context: ActionContext<JobTemplateState, any>, payload: any) {
            let response = await Ajax.get('api/job-template/' + payload.data);
            return response.data.result as JobTemplate;
        },
        async lookUp(context: ActionContext<JobTemplateState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/job-template');
            return response.data.result as Array<CountryLookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: JobTemplateState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: JobTemplateState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: JobTemplateState, jobTemplate: JobTemplate) {
            state.editJobTemplate = jobTemplate;
        }
    }
}

const jobTemplateModule = new JobTemplateModule();
export default jobTemplateModule;
