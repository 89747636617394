import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Department from '../../entities/companycontext/department'
import DepartmentVm from '../../entities/companycontext/departmentvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import DepartmentLookUp from "@/store/entities/companycontext/departmentlookup";
import LookUp from "@/store/entities/lookup";

interface DepartmentState extends ListState<DepartmentVm> {
    editDepartment: Department,
}

class DepartmentMutations extends ListMutations<Department> {

}

class DepartmentModule extends ListModule<DepartmentState, any, DepartmentVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<DepartmentVm>(),
        loading: false,
        editDepartment: new Department()
    }
    actions = {
        async getAll(context: ActionContext<DepartmentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/department/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<DepartmentVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<DepartmentState, any>, payload: any) {
            await Ajax.post('/api/department', payload.data);
        },
        async update(context: ActionContext<DepartmentState, any>, payload: any) {
            await Ajax.put('/api/department', payload.data);
        },
        async delete(context: ActionContext<DepartmentState, any>, payload: any) {
            await Ajax.delete('api/department?Id=' + payload.data.id);
        },
        async get(context: ActionContext<DepartmentState, any>, payload: any) {
            let response = await Ajax.get('api/department/' + payload.data);
            return response.data.result as Department;
        },
        async lookUp(context: ActionContext<DepartmentState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/department');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: DepartmentState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: DepartmentState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: DepartmentState, department: Department) {
            state.editDepartment = department;
        }
    }
}

const departmentModule = new DepartmentModule();
export default departmentModule;
