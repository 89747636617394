import Entity from "@/store/entities/entity";

export default class ClientJob extends Entity<number> {
    fclPackDate: string;
    actualDeliveryDateTime: string;
    estDeliveryDate: string;
    fclCollectDate: string;
    transportDeliveryInstructions: string;
    shippingMethod: string;
    supplier: string;
    collectionAddress: string;
    hbl: string;
    consignee: string;
    po: string;
    etd: string;
    note: string;
    eta: string;
    portOfLoading: string;
    portOfDischarge: string;
    estimatedDelivery: string;
    actualDelivery: string;
    deliveryAddress: string;
    transportInstructions: string;
    detentionLastFreeDay: string;
    emptyToCollect: string;
    emptyCollected: string;
    emptyDehired: string;
    consolidationNumber: number;
    deliveryAddressFirstLine: string;
    deliveryAddressSubdivision: string;
    deliveryAddressSecondLine: string;
    incoterm: string;
    airCargoCollectDate: string;
    owner: string;
    estimatedCollection: string;
    pieces: string;
    weight: string;
    cbm: string;
    containers: Array<ClientContainerJob>;
}

class ClientContainerJob {
    containerNumber: string;
    sealNumber: string;
    containerSize: string;
}
