import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import SubdivisionType from '../entities/subdivisiontype'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import Vue from "vue";
import LookUp from "@/store/entities/lookup";

interface SubdivisionTypeState extends ListState<SubdivisionType> {
    editSubdivisionType: SubdivisionType,
}

class SubdivisionTypeMutations extends ListMutations<SubdivisionType> {

}

class SubdivisionTypeModule extends ListModule<SubdivisionTypeState, any, SubdivisionType> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<SubdivisionType>(),
        loading: false,
        editCountry: new SubdivisionType()
    }
    actions = {
        async getAll(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/subdivision-type/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<SubdivisionType>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            await Ajax.post('api/subdivision-type', payload.data);
        },
        async update(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            await Ajax.put('api/subdivision-type', payload.data);
        },
        async delete(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            await Ajax.delete('api/subdivision-type?Id=' + payload.data.id);
        },
        async get(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            let response = await Ajax.get('api/subdivision-type/' + payload.id);
            return response.data.result as SubdivisionType;
        },
        async lookUp(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            let response = await Ajax.get('api/subdivision-type/look-up');
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<SubdivisionTypeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/subdivision-type/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: SubdivisionTypeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: SubdivisionTypeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: SubdivisionTypeState, subdivisionType: SubdivisionType) {
            state.editSubdivisionType = subdivisionType;
        }
    }
}

const subdivisionTypeModule = new SubdivisionTypeModule();
export default subdivisionTypeModule;