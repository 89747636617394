import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Country from '../entities/country'
import CountryLookUp from "../entities/countryLookUp";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import Vue from "vue";
import LookUp from "@/store/entities/lookup";

interface CountryState extends ListState<Country> {
    editCountry: Country,
}

class CountryMutations extends ListMutations<Country> {

}

class CountryModule extends ListModule<CountryState, any, Country> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Country>(),
        loading: false,
        editCountry: new Country()
    }
    actions = {
        async getAll(context: ActionContext<CountryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/country/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Country>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CountryState, any>, payload: any) {
            await Ajax.post('api/country', payload.data);
        },
        async update(context: ActionContext<CountryState, any>, payload: any) {
            await Ajax.put('api/country', payload.data);
        },
        async delete(context: ActionContext<CountryState, any>, payload: any) {
            await Ajax.delete('api/country?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CountryState, any>, payload: any) {
            let response = await Ajax.get('api/country/' + payload.id);
            return response.data.result as Country;
        },
        async lookUp(context: ActionContext<CountryState, any>) {
            let response = await Ajax.get('api/dictionary/country');
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<CountryState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/country/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then((response) => {
                    vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: CountryState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CountryState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: CountryState, country: Country) {
            state.editCountry = country;
        }
    }
}

const countryModule = new CountryModule();
export default countryModule;
