
import { Component, Vue,Inject, Prop } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase'
@Component
export default class extends AbpBase {
    name:string= 'breadcrumbNav';
    @Prop({type:Array}) currentPath:Array<any>;
    itemTitle (item:any) {
        return this.L(item.meta.title);
    }
}
