import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Document from '../../entities/jobcontext/document'
import Ajax from '../../../lib/ajax'
import PageResult from "../../entities/page-result";
import LookUp from "../../entities/lookup";
import DocumentVm from "@/store/entities/jobcontext/documentvm";

interface DocumentState extends ListState<DocumentVm> {
    editDocument: Document,
}

class DocumentModule extends ListModule<DocumentState, any, DocumentVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<DocumentVm>(),
        loading: false,
        editDocument: new Document()
    }
    actions = {
        async getAll(context: ActionContext<DocumentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/document/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<DocumentVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<DocumentState, any>, payload: any) {
            await Ajax.post('/api/document', payload.data);
        },
        async update(context: ActionContext<DocumentState, any>, payload: any) {
            await Ajax.put('/api/document', payload.data);
        },
        async delete(context: ActionContext<DocumentState, any>, payload: any) {
            await Ajax.delete('/api/document?Id=' + payload.data.id);
        },
        async get(context: ActionContext<DocumentState, any>, payload: any) {
            let response = await Ajax.get('/api/document/' + payload.data);
            return response.data.result as Document;
        },
        async lookUp(context: ActionContext<DocumentState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/document');
            return response.data.result as Array<LookUp>;
        },
        async emailLookUp(context: ActionContext<DocumentState, any>, payload: any) {
            let response = await Ajax.get('/api/job/mail-job-documents?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async companyTypes(context: ActionContext<DocumentState, any>) {
            let response = await Ajax.get('/api/dictionary/request-document-company-type');
            return response.data.result as Array<LookUp>;
        },
        async dynamicPropertiesTypes(context: ActionContext<DocumentState, any>) {
            let response = await Ajax.get('/api/dictionary/document-dynamic-property-type');
            return response.data.result as Array<LookUp>;
        },
        async getPageOrientationTypes(context: ActionContext<DocumentState, any>){
            let response = await Ajax.get('/api/dictionary/page-orientations');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: DocumentState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: DocumentState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: DocumentState, document: Document) {
            state.editDocument = document;
        }
    }
}

const documentModule = new DocumentModule();
export default documentModule;
