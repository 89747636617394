import Entity from '../entity'
export default class CompanyContact extends Entity<number> {
    companyId : number;
    contactId : number;
    manageCompany: boolean;
    position: string;
    employmentStart: Date | null;
    employmentEnd: Date | null;
    emailAddress: string;
    landline: string;
    mobilePhone: string;
    employmentType: number | null;
    departments: Array<number>;
    type: number;
}
