export default class JobOverview {
    id: number;
    consolidationNumber: number;
    client: string;
    hblDocumentAvailability: boolean;
    hblDocumentMustBePresented: boolean;
    mblDocumentAvailability: boolean;
    mblDocumentMustBePresented: boolean;
    oblDocumentAvailability: boolean;
    oblDocumentMustBePresented: boolean;
    mawbDocumentAvailability: boolean;
    mawbDocumentMustBePresented: boolean;
    hawbDocumentAvailability: boolean;
    hawbDocumentMustBePresented: boolean;
    civDocumentAvailability: boolean;
    civDocumentMustBePresented: boolean;
    cooDocumentAvailability: boolean;
    cooDocumentMustBePresented: boolean;
    pkdDocumentAvailability: boolean;
    pkdDocumentMustBePresented: boolean;
    customsEntryDocumentAvailability: boolean;
    customsEntryDocumentMustBePresented: boolean;
    dsbDocumentAvailability: boolean;
    dsbDocumentMustBePresented: boolean;
    invCDocumentAvailability: boolean;
    invCDocumentMustBePresented: boolean;
    invFDocumentAvailability: boolean;
    invFDocumentMustBePresented: boolean;
    caDocumentAvailability: boolean;
    caMustBePresented: boolean;
    departureActionAvailability: boolean;
    departureActionMustBePresented: boolean;
    arrivalNoticeActionAvailability: boolean;
    arrivalNoticeMustBePresented: boolean;
    caSentActionAvailability: boolean;
    caSentMustBePresented: boolean;
    caInActionAvailability: boolean;
    caInMustBePresented: boolean;
    sentCciActionAvailability: boolean;
    sentCciMustBePresented: boolean;
    sentCceActionAvailability: boolean;
    sentCceMustBePresented: boolean;
    payShippingLineActionAvailability: boolean;
    payShippingLineMustBePresented: boolean;
    deliveryRequestToTransportActionAvailability: boolean;
    deliveryRequestToTransportMustBePresented: boolean;
    cartageRequestToTransportActionAvailability: boolean;
    cartageRequestToTransportMustBePresented: boolean;
}
