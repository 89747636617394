import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import Subdivision from '../entities/subdivision'
import SubdivisionVm from '../entities/subdivisionVm'
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import CountryLookUp from "@/store/entities/countryLookUp";
import Subdivisionlookup from "@/store/entities/subdivisionlookup";
import Vue from "vue";
import LookUp from "@/store/entities/lookup";

interface SubdivisionState extends ListState<SubdivisionVm> {
    editSubdivision: Subdivision,
}

class UserMutations extends ListMutations<Subdivision> {

}

class SubdivisionModule extends ListModule<SubdivisionState, any, SubdivisionVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<SubdivisionVm>(),
        loading: false,
        editSubdivision: new Subdivision()
    }
    actions = {
        async getAll(context: ActionContext<SubdivisionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/subdivision/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<SubdivisionVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<SubdivisionState, any>, payload: any) {
            await Ajax.post('/api/subdivision', payload.data);
        },
        async update(context: ActionContext<SubdivisionState, any>, payload: any) {
            await Ajax.put('/api/subdivision', payload.data);
        },
        async delete(context: ActionContext<SubdivisionState, any>, payload: any) {
            await Ajax.delete('api/subdivision?Id=' + payload.data.id);
        },
        async get(context: ActionContext<SubdivisionState, any>, payload: any) {
            let response = await Ajax.get('api/subdivision/' + payload.data);
            return response.data.result as Subdivision;
        },
        async lookUp(context:ActionContext<SubdivisionState,any>,payload:any) {
            let response=await Ajax.get('/api/subdivision/look-up');
            return response.data.result as Array<Subdivisionlookup>;
        },
        async byCountryId(context: ActionContext<SubdivisionState, any>, payload: any) {
            let response = await Ajax.get('api/subdivision/look-up/country?countryId=' + payload.data);
            return response.data.result as Array<Subdivisionlookup>;
        },
        async uploadFile(context: ActionContext<SubdivisionState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('api/subdivision/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: SubdivisionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: SubdivisionState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: SubdivisionState, subdivision: Subdivision) {
            state.editSubdivision = subdivision;
        }
    }
}

const subdivisionModule = new SubdivisionModule();
export default subdivisionModule;