import AppConsts from "../lib/appconst";

export default class AppUrlService {
    static tenancyNamePlaceHolder = '{TENANCY_NAME}';

    getAppRootUrlOfTenant(tenancyName: string | null): string {
        let baseUrl = this.ensureEndsWith(AppConsts.appBaseUrl, '/');

        // //Add base href if it is not configured in appconfig.json
        // if (baseUrl.indexOf(AppConsts.appBaseHref) < 0) {
        //     baseUrl = baseUrl + this.removeFromStart(AppConsts.appBaseHref, '/');
        // }

        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder) < 0) {
            return baseUrl;
        }

        if (baseUrl.indexOf(AppUrlService.tenancyNamePlaceHolder + '.') >= 0) {
            baseUrl = baseUrl.replace(AppUrlService.tenancyNamePlaceHolder + '.', AppUrlService.tenancyNamePlaceHolder);
            if (tenancyName) {
                tenancyName = tenancyName + '.';
            }
        }

        if (!tenancyName) {
            return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, '');
        }

        return baseUrl.replace(AppUrlService.tenancyNamePlaceHolder, tenancyName);
    }

    private ensureEndsWith(str: string, c: string) {
        if (str.charAt(str.length - 1) !== c) {
            str = str + c;
        }

        return str;
    }
}
