import Entity from '../entity'
export default class Container extends Entity<number> {
    number: string;
    isoCodeId: number | null;
    isVerified: boolean;
    typeId: number;
    sizeId: number;
    tareWeight: number;
    photo: any;
    uploadedPhoto: any;
}
