
    import AbpBase from "../../../../lib/abpbase";
    import {Component, Prop} from "vue-property-decorator";
    import ClientJob from "../../../../store/entities/jobcontext/clientjob";

    @Component
    export default class JobClientFormData extends AbpBase {
        @Prop({default: new ClientJob()}) job: ClientJob;

        showContainers() {
            return this.job.shippingMethod === 'FCL' || this.job.shippingMethod === 'FCX';
        }
    }
