import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import CompanyInstruction from '../../entities/companycontext/companyinstruction'
import CompanyInstructionVm from '../../entities/companycontext/companyrelationshipvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import CompanyInstructionsCompanyFieldsConfiguration
    from "@/store/entities/companycontext/companyinstructioncompanyfieldconfiguration";
import LookUp from "@/store/entities/lookup";

interface CompanyInstructionState extends ListState<CompanyInstructionVm> {
    editCompanyInstruction: CompanyInstruction,
}

class AddressMutations extends ListMutations<CompanyInstruction> {

}

class CompanyInstructionModule extends ListModule<CompanyInstructionState, any, CompanyInstructionVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CompanyInstructionVm>(),
        loading: false,
        editCompanyInstruction: new CompanyInstruction()
    }
    actions = {
        async getAll(context: ActionContext<CompanyInstructionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/company-instruction/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<CompanyInstructionVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CompanyInstructionState, any>, payload: any) {
            await Ajax.post('/api/company-instruction', payload.data);
        },
        async update(context: ActionContext<CompanyInstructionState, any>, payload: any) {
            await Ajax.put('/api/company-instruction', payload.data);
        },
        async delete(context: ActionContext<CompanyInstructionState, any>, payload: any) {
            await Ajax.delete('api/company-instruction?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CompanyInstructionState, any>, payload: any) {
            let response = await Ajax.get('api/company-instruction/' + payload.data);
            return response.data.result as CompanyInstruction;
        },
        async getCompaniesConfiguration(context: ActionContext<CompanyInstructionState, any>) {
            let response = await Ajax.get('api/company-instruction/company-configuration');
            return response.data.result as Array<CompanyInstructionsCompanyFieldsConfiguration>;
        },
        async getTypes(context: ActionContext<CompanyInstructionState, any>) {
            let response = await Ajax.get('api/dictionary/company-instruction-type');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: CompanyInstructionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CompanyInstructionState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: CompanyInstructionState, companyInstruction: CompanyInstruction) {
            state.editCompanyInstruction = companyInstruction;
        }
    }
}

const companyInstructionModule = new CompanyInstructionModule();
export default companyInstructionModule;
