import {Store, Module, ActionContext} from 'vuex'
import ListModule from './list-module'
import ListState from './list-state'
import PackageType from '../entities/packagetype'
import PackageTypeLookUp from "../entities/packagetypelookup";
import Ajax from '../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from './list-mutations'
import LookUp from "@/store/entities/lookup";

interface PackageTypeState extends ListState<PackageType> {
    editPackageType: PackageType,
}

class PackageTypeMutations extends ListMutations<PackageType> {

}

class PackageTypeModule extends ListModule<PackageTypeState, any, PackageType> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<PackageType>(),
        loading: false,
        editVesselType: new PackageType()
    }
    actions = {
        async getAll(context: ActionContext<PackageTypeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/package-type/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<PackageType>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<PackageTypeState, any>, payload: any) {
            await Ajax.post('api/package-type', payload.data);
        },
        async update(context: ActionContext<PackageTypeState, any>, payload: any) {
            await Ajax.put('api/package-type', payload.data);
        },
        async delete(context: ActionContext<PackageTypeState, any>, payload: any) {
            await Ajax.delete('api/package-type?Id=' + payload.data.id);
        },
        async get(context: ActionContext<PackageTypeState, any>, payload: any) {
            let response = await Ajax.get('api/package-type/' + payload.id);
            return response.data.result as PackageType;
        },
        async lookUp(context: ActionContext<PackageTypeState, any>) {
            let response = await Ajax.get('api/dictionary/package-type');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: PackageTypeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: PackageTypeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: PackageTypeState, packageType: PackageType) {
            state.editPackageType = packageType;
        }
    }
}

const packageTypeModule = new PackageTypeModule();
export default packageTypeModule;
