import Entity from '../entity'
export default class Address extends Entity<number> {
    name: string;
    isThirdParty: boolean;
    thirdPartyCompanyAddressId: number;
    thirdPartyCompanyId: number;
    isDelivery: boolean;
    isCollection: boolean;
    isDefault: boolean;
    firstLine: string;
    secondLine: string;
    zipCode: string;
    cityId: number;
    companyId: number;
    countryId: number;
    subdivisionId: number;
    city: string;
}
