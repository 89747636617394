
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '../../lib/abpbase'
    import * as moment from 'moment'
    import {PerfectScrollbar} from 'vue2-perfect-scrollbar'
    import Entity from "@/store/entities/entity";

    @Component({
        components: {PerfectScrollbar}
    })
    export default class Notice extends AbpBase {
        noticeSpinShow: boolean = true;

        get noticeList(): Array<any> {
            return this.$store.state.app.noticeList;
        }

        async readAllNotifications() {
            await this.$store.commit('app/readAllNotifications');
        }

        async redirectToAction(notice) {
            if (notice.jobId === null || notice.jobId === "") {
            } else {
                await this.$router.push({name: 'job-form', params: {jobId: notice.jobId}});
            }
            if (notice.orderId === null || notice.orderId === "") {
            } else {
                if (window.abp.auth.hasPermission('Pages.AdminOrder')) {
                    await this.$router.push({name: 'admin-order', params: {orderId: notice.orderId}});
                }
            }
        }

        // async readNotification(index: number) {
        //     // let input = new Entity<string>();
        //     // input.id = this.$store.state.app.noticeList[index].id;
        //     // await this.$store.commit('app/readNotification', {
        //     //     id: input,
        //     //     index: index
        //     // });
        // }

        getDescription(index: number) {
            let date = this.$store.state.app.noticeList[index].date as Date;
            return moment(this.convertUTCDateToLocalDate(date as Date)).fromNow();
        }

        convertUTCDateToLocalDate(date) {
            return new Date(date.toString() + 'Z');
        }

        get unReadCount() {
            return this.noticeList.length
        }

        get noticeArray() {
            return this.noticeList;
        }

        get noticeCount() {
            return this.noticeList.length
        }

        get messageArray() {
            return this.noticeList.filter(n => n.read == false && n.type === 1)
        }

        get messageCount() {
            return this.noticeList.filter(n => n.read == false && n.type === 1).length
        }

        get taskArray() {
            return this.noticeList.filter(n => n.read == false && n.type === 2)
        }

        get taskCount() {
            return this.noticeList.filter(n => n.read == false && n.type === 2).length
        }

        get noticeLabel() {
            let name = this.L('Notice');
            if (this.noticeCount > 0) {
                return `${name}(${this.noticeCount})`
            } else {
                return name
            }
        }

        get messageLabel() {
            let name = this.L('Message');
            if (this.messageCount > 0) {
                return `${name}(${this.messageCount})`
            } else {
                return name
            }
        }

        get taskLabel() {
            let name = this.L('Task')
            if (this.taskCount > 0) {
                return `${name}(${this.taskCount})`
            } else {
                return name
            }
        }

        async getNotices() {
            await this.$store.commit('app/getUserNotifications');
        }

        async mounted() {
            await this.getNotices();
        }
    }
