import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Action from '../../entities/jobcontext/action'
import Ajax from '../../../lib/ajax'
import PageResult from "../../entities/page-result";
import LookUp from "@/store/entities/lookup";


interface ActionState extends ListState<Action> {
    editAction: Action,
}

class ActionModule extends ListModule<ActionState, any, Action> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Action>(),
        loading: false,
        editAction: new Action()
    }
    actions = {
        async getAll(context: ActionContext<ActionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/action/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<Action>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ActionState, any>, payload: any) {
            await Ajax.post('/api/action', payload.data);
        },
        async update(context: ActionContext<ActionState, any>, payload: any) {
            await Ajax.put('/api/action', payload.data);
        },
        async delete(context: ActionContext<ActionState, any>, payload: any) {
            await Ajax.delete('/api/action?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ActionState, any>, payload: any) {
            let response = await Ajax.get('/api/action/' + payload.data);
            return response.data.result as Action;
        },
        async lookUp(context: ActionContext<ActionState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/action');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: ActionState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ActionState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: ActionState, action: Action) {
            state.editAction = action;
        }
    }
}

const actionModule = new ActionModule();
export default actionModule;
