import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import CustomerShippingOrder from '../../entities/ordercontext/customershippingorder'
import AdminShippingOrder from "@/store/entities/ordercontext/adminshippingorder";
import ContainerVm from '../../entities/containercontext/containervm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import CountryLookUp from "@/store/entities/countryLookUp";
import LookUp from "@/store/entities/lookup";
import CreateShippingOrderJob from "@/store/entities/ordercontext/createshippingorderjob";

interface OrderState extends ListState<ContainerVm> {
    editCustomerShippingOrder: CustomerShippingOrder,
    editAdminShippingOrder: AdminShippingOrder,
    createJob: CreateShippingOrderJob
}

class ContainerModule extends ListModule<OrderState, any, ContainerVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ContainerVm>(),
        loading: false,
        editCustomerShippingOrder: new CustomerShippingOrder(),
        editAdminShippingOrder: new AdminShippingOrder(),
        createJob: new CreateShippingOrderJob()
    }
    actions = {
        async getAll(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/shipping-order/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ContainerVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async createForCustomer(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.post('/api/shipping-order/customer', payload.data);
            console.log(response.data);
            return response.data.result;
        },
        async updateForCustomer(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.put('/api/shipping-order/customer', payload.data);
        },
        async createForAdmin(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.post('/api/shipping-order/admin', payload.data);
            console.log(response.data);
            return response.data.result;
        },
        async updateForAdmin(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.put('/api/shipping-order/admin', payload.data);
        },
        async delete(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.delete('api/shipping-order?Id=' + payload.data.id);
        },
        async getCustomer(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.get('api/shipping-order/customer/' + payload.data);
            return response.data.result as CustomerShippingOrder;
        },
        async getAdmin(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.get('api/shipping-order/admin/' + payload.data);
            return response.data.result as AdminShippingOrder;
        },
        async shippingMethods(context: ActionContext<OrderState, any>) {
            let response = await Ajax.get('api/dictionary/shipping-method');
            return response.data.result as Array<LookUp>;
        },
        async shippingStatuses(context: ActionContext<OrderState, any>) {
            let response = await Ajax.get('api/dictionary/shipping-status');
            return response.data.result as Array<LookUp>;
        },
        async createJob(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.post('api/shipping-order/create-job', payload.data);
        },
        async clone(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.post('/api/shipping-order/clone/' + payload.data);
        },
        async mailToLink(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.get('api/shipping-order/mailto-link/' + payload.data);
            return response.data.result as string;
        },
        async getDocuments(context: ActionContext<OrderState, any>, payload) {
            let response = await Ajax.get('api/shipping-order-document/' + payload);
            return response.data.result;
        },
        async updateDocument(context: ActionContext<OrderState, any>, payload) {
            await Ajax.put('/api/shipping-order-document', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async removeFile(context: ActionContext<OrderState, any>, payload: any) {
            await Ajax.delete('/api/shipping-order-document/' + payload);
        },
        async previewDocument(context: ActionContext<OrderState, any>, payload: any) {
            let response = await Ajax.get('/api/shipping-order-document/download/' + payload, {responseType: 'blob'});
            let file = new Blob([response.data], {type: response.headers['content-type']});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },
    };
    mutations = {
        setCurrentPage(state: OrderState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: OrderState, pageSize: number) {
            state.pageSize = pageSize;
        },
        editCustomerShippingOrder(state: OrderState, customerShippingOrder: CustomerShippingOrder) {
            state.editCustomerShippingOrder = customerShippingOrder;
        },
        editAdminShippingOrder(state: OrderState, adminShippingOrder: AdminShippingOrder) {
            state.editAdminShippingOrder = adminShippingOrder;
        },
        setShippingOrderJob(state: OrderState, shippingOrderJob: CreateShippingOrderJob) {
            state.createJob = shippingOrderJob;
        }
    }
}

const containerModule = new ContainerModule();
export default containerModule;
