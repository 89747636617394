import Entity from '../entity'
import JobContainer from "@/store/entities/jobcontext/jobcontainer";
import JobItem from "@/store/entities/jobcontext/jobitem";

export default class Job extends Entity<number> {
    clientId: number | null;
    jobTemplateId: number;
    shippingOrderId: number | null;
    consoleActualDeliveryDateTime: Date | null;
    deliveryToDepot: Date | null;
    ata: Date | null;
    atd: Date | null;
    clientNote: string;
    isCompleted : boolean;
    availabilityConsole: Date | null;
    consoleTerminalAddressId: number | null;
    bookingNumberConsole: string;
    collectionAddressId: number | null;
    consoleCollectionAddressId: number | null;
    masterPrepaidCollectTypeEnum: number | null;
    consoleShippingMethodId: number | null;
    containerReleaseConsole: string;
    hsCode: string;
    estimatedCollection: Date;
    consoleEstimatedCollection: Date;
    consoleCutOff: Date | null;
    deliveryAddressId: number | null;
    consoleDeliveryAddressId: number | null;
    destinationCustomsBrokerId: number | null;
    destinationTerminalId: number | null;
    consoleDestinationTerminalId: number | null;
    destinationTransportReference: string;
    consoleDestinationTransportReference: string | null;
    consoleDestinationWarehouseId: number | null;
    consoleDetentionDays: string;
    consoleDetentionLastFreeDay: Date | null;
    emptyCollectDepotId: number | null;
    consoleEmptyCollectDepotId: number | null;
    consoleEmptyCollected: Date | null;
    consoleEmptyDehired: Date | null;
    consoleEmptyReturnDepotId: number | null;
    consoleReadyToCollect: Date | null;
    consoleEstDeliveryDate: Date | null;
    consoleExportClearanceNumber: string;
    consoleFclDropDate: Date | null;
    fclPackDate: Date | null;
    consoleFclPackDate: Date | null;
    hawb: string;
    hazardousClass: string;
    insurancePolicyNumber: string;
    lclCollectionDate: Date | null;
    mawb: string;
    mbl: string;
    consoleOriginCustomBrokerId: number | null;
    originTransportReference: string;
    consoleOriginTransportReference: string;
    placeOfReceiptId: number | null;
    consolePortReceivalAdvice: string;
    receivalStartDate: Date | null;
    consoleReceivalStartDate: Date | null;
    requireInsurance: boolean;
    consoleScheduledDeliveryDateTime: Date | null;
    consoleStorageCommences: Date | null;
    consoleTerminalId: number | null;
    consoleTimeSlot: Date | null;
    consoleTimeSlotToPort: Date | null;
    transportCollectionTime: string;
    consoleTransportCollectionTime: string;
    consoleTransportCompanyId: number | null;
    consoleTransportDeliveryInstructions: string;
    transportDeliveryTime: Date | null;
    consoleTransportDeliveryTime: Date | null;
    transportDeliveryVehicleTypeId: number | null;
    consoleTransportDeliveryVehicleTypeId: number | null;
    consoleTransportInstructions: string;
    transportVehicleTypeId: number | null;
    consoleTransportVehicleTypeId: number | null;
    warehouseId: number | null;
    consoleWarehouseId: number | null;
    shippingMethod: number | null;
    supplierId: number | null;
    consigneeId: number | null;
    po: string;
    originId: number | null;
    destinationId: number | null;
    originAgentId: number | null;
    destinationAgentId: number | null;
    incotermId: number | null;
    portOfLoadingId: number | null;
    finalDestinationId: number | null;
    portOfDischargeId: number | null;
    originShippingLineId: number | null;
    bookingNumber: string;
    containerRelease: string;
    vesselId: number | null;
    lloyds: string;
    voyage: string;
    airlineId: number | null;
    flight: string;
    cutOff: Date | null;
    etd: Date | null;
    eta: Date | null;
    containerId: number | null;
    transportCompanyId: number | null;
    transportInstructions: string;
    fclDropDate: Date | null;
    fclCollectDate: Date | null;
    numberOfPackages: string;
    packageTypeId: number | null;
    goodsDescription: string;
    marksAndNumbers: string;
    netWeight: string;
    volume: string
    dimensions: string;
    exportClearanceNumber: string;
    portReceivalAdvice: string;
    timeSlotToPort: Date;
    timeSlot: Date;
    hbl: string;
    obl: string;
    destinationShippingLineId: number | null;
    originCustomBrokerId: number | null;
    transportCompanyDestinationId: number | null;
    terminalId: number | null;
    destinationWarehouseId: number | null;
    availability: Date | null;
    storageCommence: Date | null;
    detentionDays: string;
    detentionLastFreeDay: Date;
    emptyReturnDepotId: number | null;
    transportDeliveryInstructions: string;
    estDeliveryDate: Date | null;
    scheduledDeliveryDateTime: Array<string>;
    scheduledDeliveryDate: Date | null;
    consoleScheduledDeliveryTimeWindow: Array<string>;
    actualDeliveryDateTime: Date | null;
    readyToCollect: Date | null;
    emptyCollected: Date | null;
    emptyDehired: Date | null;
    consolidationId: number | null;
    note: string;
    consolidationNumber: number;
    consoleFclCollectDate: Date | null;
    cargoReportingAgentId: number | null;
    directMaster: boolean;
    freightForwarderIndicator: boolean;
    carrierCodeId: number | null;
    containers: Array<number>;
    jobContainers: Array<JobContainer>;
    volumeTotal: number;
    hazardousClasses: string;
    netWeightTotal: number;
    consolidationHazardousClasses: string;
    consolidationNetWeightTotal: number;
    consolidationVolumeTotal: number;
    consolidationNumberOfPackages: number;
    importDisabled: boolean;
    createdDate: string;
    grossWeight: string;
    chargeableWeight: string;
    currentNodeId: number | null;
    createJobEnabled: boolean;
    ownerId: number;
    controllingAgentId: number;
    consoleRequireInsurance: boolean;
    consoleInsurancePolicyNumber: string | null;
    consoleDestinationCustomsBrokerId: number | null;
    consoleTransportCompanyDestinationId: number | null;
    consoleCargoReportingAgentId: number | null;
    lclCargoCollectDate: Date | null;
    consoleDeliveryToDepot: Date | null;
    cost: string;
    estFreightCostCurrencyId: number;
    finalFreightCostCurrencyId: number;
    estFreightCost: number;
    finalFreightCost: number;
    consolidationFortySizeTotal: number;
    consolidationTwentySizeTotal: number;
    isLocked: boolean;
    tagId: number;
    billStatus: number | null;
    consoleBillStatus: number | null;
    items: Array<JobItem>;

    public Job(){
        this.items = new Array<JobItem>();
    }
}
