import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Container from '../../entities/containercontext/container'
import ContainerVm from '../../entities/containercontext/containervm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import LookUp from "@/store/entities/lookup";
import ContainerLookUp from "@/store/entities/containercontext/containerlookup";

interface ContainerState extends ListState<ContainerVm> {
    editContainer: Container,
}

class ContainerMutations extends ListMutations<Container> {

}

class ContainerModule extends ListModule<ContainerState, any, ContainerVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ContainerVm>(),
        loading: false,
        editContainer: new Container()
    }
    actions = {
        async getAll(context: ActionContext<ContainerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/container/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ContainerVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ContainerState, any>, payload: any) {
            let response = await Ajax.post('/api/container', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data.result;
        },
        async update(context: ActionContext<ContainerState, any>, payload: any) {
            await Ajax.put('/api/container', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async delete(context: ActionContext<ContainerState, any>, payload: any) {
            await Ajax.delete('api/container?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ContainerState, any>, payload: any) {
            let response = await Ajax.get('api/container/' + payload.data);
            return response.data.result as Container;
        },
        async lookUp(context: ActionContext<ContainerState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/container?keyWord=' + payload);
            return response.data.result as Array<ContainerLookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: ContainerState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ContainerState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ContainerState, container: Container) {
            state.editContainer = container;
        }
    }
}

const containerModule = new ContainerModule();
export default containerModule;
