import {ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import JobVm from '../../entities/jobcontext/jobvm'
import Ajax from '../../../lib/ajax'
import PageResult from "../../entities/page-result";
import LookUp from "@/store/entities/lookup";
import Job from "@/store/entities/jobcontext/job";
import FormInput from "@/components/dynamic-forms/form-field";
import JobNodeMetadataVm from "@/store/entities/jobcontext/jobnodemetadatavm";
import JobCompanyInstructionsComparisonVm from "@/store/entities/jobcontext/jobcompanyinstructionscomparisonvm";
import Vue from "vue";
import JobNodeLookUp from "@/store/entities/jobcontext/jobnodelookup";
import ContainerLookUp from "@/store/entities/containercontext/containerlookup";
import DocumentRequest from "@/store/entities/jobcontext/documentrequest";
import PreviewEmail from "@/store/modules/notificationcontext/previewemail";
import {Dictionary} from "vue-router/types/router";
import JobContainer from "@/store/entities/jobcontext/jobcontainer";

interface JobState extends ListState<JobVm> {
    listFieldsMetadata: Array<FormInput>;
    listNodeFieldsMetadata: Array<FormInput>;
    editJob: Job;
    consolidationJobs: Array<JobVm>;
}

class JobModule extends ListModule<JobState, any, JobVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<JobVm>(),
        consolidationJobs: new Array<JobVm>(),
        loading: false,
        listFieldsMetadata: new Array<FormInput>(),
        listNodeFieldsMetadata: new Array<FormInput>(),
        editJob: Job
    }
    actions = {
        async getAll(context: ActionContext<JobState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/job/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<JobVm>;
            context.state.totalCount = page.totalCount;
            page.items.forEach(x => {
                if (x.children === null) {
                    delete x['children'];
                }
                if (x._loading === true) {
                    delete x['_loading'];
                }
            })
            context.state.list = page.items;
        },
        async getJobsOfConsolidation(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.post('/api/job/get-consolidation-jobs', payload.data);
            let result = response.data.result as Array<JobVm>;
            result.forEach(x => {
                delete x['children'];
                delete x['_loading'];
            })
            return result;
        },
        async getConsolidationJobs(context: ActionContext<JobState, any>, payload: any) {
            context.state.consolidationJobs = [];
            let response = await Ajax.get('/api/job/consolidation-job/' + payload);
            context.state.consolidationJobs = response.data.result as Array<JobVm>;
        },
        async downloadJobDocument(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document/download?id=' + payload, {responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'test.jpg';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match('filename="?([^"]+)"?;');
                if (fileNameMatch && fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        },
        async previewDocument(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document/download?id=' + payload, {responseType: 'blob'});
            let file = new Blob([response.data], {type: response.headers['content-type']});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },
        async removeFile(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.delete('/api/job-document?id=' + payload);
        },
        async getClientJobDocuments(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document/client?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getJobDocuments(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document?jobId=' + payload);
            return response.data.result;
        },
        async getConsolidationJobDocuments(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document/consolidation?jobId=' + payload);
            return response.data.result;
        },
        async getMasterPrepaidCollection(context: ActionContext<JobState, any>) {
            let response = await Ajax.get('/api/dictionary/master-prepaid');
            return response.data.result as Array<LookUp>;
        },
        async getShippingOrders(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/job-shipping-order?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getConsolidation(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/job-consolidation?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getNodes(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-nodes?jobId=' + payload);
            return response.data.result as Array<JobNodeLookUp>;
        },
        async getNotIncludedNodes(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-nodes-not-included?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async addJobNode(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/node', payload.data);
        },
        async createTemplate(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/template', payload.data);
        },
        async update(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/job', payload.data).then(() => {
                vm.$Modal.success({title: 'Changes were applied'});
            });
        },
        async updateJobField(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/job-field', payload.data);
        },
        async updateDocument(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.put('/api/job-document', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async cloneJob(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/clone/' + payload.data);
        },
        async getNodeFields(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-node-metadata', {params: payload});
            context.state.listNodeFieldsMetadata = response.data.result as Array<FormInput>;
        },
        async create(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.post('/api/job', payload.data);
            return response.data.result;
        },
        async get(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/' + payload);
            context.state.editJob = response.data.result as Job;
        },
        async getClientView(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/client-view/' + payload);
            return response.data.result as Job;
        },
        async getRequiredFields(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/required-fields/' + payload);
            return response.data.result as Array<string>;
        },
        async getMetadata(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/fields-metadata?jobId=' + payload);
            context.state.listFieldsMetadata = response.data.result as Array<FormInput>;
        },
        async getJobNodeDocumentsMetadata(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-node-documents', {params: payload});
            return response.data.result as Array<JobNodeMetadataVm>;
        },
        async getJobNodeActionsMetadata(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-node-actions', {params: payload});
            return response.data.result as Array<JobNodeMetadataVm>;
        },
        async getJobNodeFieldsMetadata(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-node-fields', {params: payload});
            return response.data.result as Array<JobNodeMetadataVm>;
        },
        async getJobDefaultData(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-import-data', {params: payload});
            return response.data.result as Array<JobCompanyInstructionsComparisonVm>;
        },
        async applyAllInstruction(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.patch('/api/job/job-instruction-apply-all', payload.data).then(() => {
                vm.$Modal.success({title: 'Changes were applied'});
            });
        },
        async applyInstruction(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.patch('/api/job/job-instruction-apply', payload.data).then(() => {
                vm.$Modal.success({title: 'Changes were applied'});
            });
        },
        async applyAllConsolidationInstruction(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.patch('/api/job/consolidation-instruction-apply-all', payload.data).then(() => {
                vm.$Modal.success({title: 'Changes were applied'});
            });
        },
        async applyConsolidationInstruction(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.patch('/api/job/consolidation-instruction-apply', payload.data).then(() => {
                vm.$Modal.success({title: 'Changes were applied'});
            });
        },
        async generateDocument(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/job-document/generate', payload).then(() => {
                vm.$Modal.success({title: 'Document was generated'});
            });
        },
        async updateJobCurrentNode(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/job/set-current-node', payload).then(() => {
                vm.$Modal.success({title: 'Node is set successfully'});
            });
        },
        async getContainers(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.post('/api/job/containers', payload);
            return response.data.result as Array<ContainerLookUp>;
        },
        async email(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/job-email', payload).then((response) => {
                vm.$Modal.success({title: 'Success'})
            });
        },
        async documentRequest(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/document-request?jobId=' + payload);
            return response.data.result as DocumentRequest;
        },
        async missedDocuments(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job-document/missed?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async getContainersForLookUp(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/containers-lookup?jobId=' + payload);
            return response.data.result as Array<LookUp>;
        },
        async updateJobTemplate(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.put('/api/job/job-template', payload);
        },
        async previewEmail(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.post('/api/job-email/preview',  payload);
            return response.data.result as PreviewEmail;
        },
        async lookUp(context: ActionContext<JobState, any>) {
            let response = await Ajax.get('/api/dictionary/job');
            return response.data.result as Array<LookUp>;
        },
        async notify(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/users-notify', payload);
        },
        async getTemplateCompanies(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/email-template-companies', {params: payload});
            return response.data.result as Array<LookUp>;
        },
        async notifyOwner(context: ActionContext<JobState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/job/notify-owner', payload.data).then((response) => {
                vm.$Modal.success({title: 'Success'})
            });
        },
        async lock(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/lock/' + payload.data);
        },
        async unlock(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/unlock/' + payload.data);
        },
        async getBillStatuses(context: ActionContext<JobState, any>) {
            let response = await Ajax.get('/api/dictionary/bill-status-type');
            return response.data.result as Array<LookUp>;
        },
        async packContainer(context: ActionContext<JobState, any>, payload: any) {
            await Ajax.post('/api/job/container/pack', payload.data);
        },
        async getContainersForJob(context: ActionContext<JobState, any>, payload: any) {
            let response = await Ajax.get('/api/job/job-containers?jobId=' + payload);
            return response.data.result as Array<JobContainer>;
        },
        async getJobOverview(context: ActionContext<JobState, any>, payload: any){
            let response = await Ajax.post('/api/job/overview/' + payload);
            return response.data.result;
        },
        async markDocumentAsNotApplicable(context: ActionContext<JobState, any>, payload: any){
            await Ajax.post('/api/job-document/not-applicable', payload);
        },
        async getNotApplicableDocuments(context: ActionContext<JobState, any>, payload: any){
            let response = await Ajax.get('/api/job-document/not-applicable/' + payload);
            return response.data.result;
        },
        async markDocumentAsApplicable(context: ActionContext<JobState, any>, payload: any){
            await Ajax.post('/api/job-document/applicable', payload);
        },
    };
    mutations = {
        setCurrentPage(state: JobState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: JobState, pageSize: number) {
            state.pageSize = pageSize;
        }
    }
}

const jobModule = new JobModule();
export default jobModule;
