import {Store, Module, ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ContainerType from '../../entities/containercontext/containertype'
import ContainerTypeLookUp from "../../entities/containercontext/containertypelookup";
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import ContainerTypeVm from "@/store/entities/containercontext/containertypevm";
import Vue from "vue";
import LookUp from "@/store/entities/lookup";

interface ContainerTypeState extends ListState<ContainerTypeVm> {
    editContainerType: ContainerType,
}

class ContainerTypeMutations extends ListMutations<ContainerType> {

}

class ContainerTypeModule extends ListModule<ContainerTypeState, any, ContainerTypeVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ContainerTypeVm>(),
        loading: false,
        editVesselType: new ContainerType()
    }
    actions = {
        async getAll(context: ActionContext<ContainerTypeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('api/container-type/get',  payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ContainerTypeVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ContainerTypeState, any>, payload: any) {
            await Ajax.post('api/container-type', payload.data);
        },
        async update(context: ActionContext<ContainerTypeState, any>, payload: any) {
            await Ajax.put('api/container-type', payload.data);
        },
        async delete(context: ActionContext<ContainerTypeState, any>, payload: any) {
            await Ajax.delete('api/container-type?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ContainerTypeState, any>, payload: any) {
            let response = await Ajax.get('api/container-type/' + payload.data);
            return response.data.result as ContainerType;
        },
        async filteredLookUp(context: ActionContext<ContainerTypeState, any>, payload: any) {
            let response = await Ajax.get('api/container-type/look-up', {params: payload});
            return response.data.result as Array<ContainerTypeLookUp>;
        },
        async lookUp(context: ActionContext<ContainerTypeState, any>) {
            let response = await Ajax.get('api/dictionary/container-type');
            return response.data.result as Array<LookUp>;
        },
        async uploadFile(context: ActionContext<ContainerTypeState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.put('/api/container-type/sync', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Successfully synced'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: ContainerTypeState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ContainerTypeState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ContainerTypeState, containerType: ContainerType) {
            state.editContainerType = containerType;
        }
    }
}

const containerTypeModule = new ContainerTypeModule();
export default containerTypeModule;
