import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import CompanyContact from '../../entities/companycontext/companycontact'
import CompanyContactVm from '../../entities/companycontext/companycontactvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '.././list-mutations'
import LookUp from "@/store/entities/lookup";

interface CompanyContactState extends ListState<CompanyContactVm> {
    editCompanyContact: CompanyContact,
}

class CompanyContactMutations extends ListMutations<CompanyContact> {

}

class CompanyContactModule extends ListModule<CompanyContactState, any, CompanyContactVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CompanyContactVm>(),
        loading: false,
        editCompanyContact: new CompanyContact()
    }
    actions = {
        async getAll(context: ActionContext<CompanyContactState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/company-contact/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<CompanyContactVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<CompanyContactState, any>, payload: any) {
            await Ajax.post('/api/company-contact', payload.data);
        },
        async update(context: ActionContext<CompanyContactState, any>, payload: any) {
            await Ajax.put('/api/company-contact', payload.data);
        },
        async delete(context: ActionContext<CompanyContactState, any>, payload: any) {
            await Ajax.delete('api/company-contact?Id=' + payload.data.id);
        },
        async get(context: ActionContext<CompanyContactState, any>, payload: any) {
            let response = await Ajax.get('api/company-contact/' + payload.data);
            return response.data.result as CompanyContact;
        },
        async getEmploymentTypes(context: ActionContext<CompanyContactState, any>, payload: any) {
            let response = await Ajax.get('/api/dictionary/employment-type');
            return response.data.result as Array<LookUp>;
        },
        async types(context: ActionContext<CompanyContactState, any>){
            let response = await Ajax.get('/api/dictionary/company-contact-type');
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: CompanyContactState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: CompanyContactState, pageSize: number) {
            state.pageSize = pageSize;
        },
        edit(state: CompanyContactState, companyContact: CompanyContact) {
            state.editCompanyContact = companyContact;
        }
    }
}

const companyContactModule = new CompanyContactModule();
export default companyContactModule;
