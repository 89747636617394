import {Store, Module, ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DocumentRequest from '../../entities/jobcontext/documentrequest'
import DocumentRequestVm from '../../entities/jobcontext/documentrequestvm'
import Ajax from '../../../lib/ajax'
import PageResult from '@/store/entities/page-result';
import ListMutations from '../list-mutations'
import Vue from "vue";
import ClientRequestDocument from "@/store/entities/jobcontext/clientrequestdocument";
import JobDocumentRequestVm from "@/store/entities/jobcontext/jobdocumentrequest";
import Country from "@/store/entities/country";

interface DocumentRequestState extends ListState<DocumentRequestVm> {
    editDocumentRequest: JobDocumentRequestVm
}

class DepartmentMutations extends ListMutations<DocumentRequest> {

}

class DocumentRequestModule extends ListModule<DocumentRequestState, any, DocumentRequestVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<DocumentRequestVm>(),
        loading: false,
        editDocumentRequest: new JobDocumentRequestVm()
    }
    actions = {
        async getAll(context: ActionContext<DocumentRequestState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/document-request/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<DocumentRequestVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/document-request', payload.data).then((response) => {
                vm.$Notice.success({title: 'Success'})
            });
        },
        async getDocuments(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let response = await Ajax.get('api/client-document-request?identifier=' + payload)
            return response.data.result as Array<ClientRequestDocument>;
        },
        async getJob(context: ActionContext<DocumentRequestState, any>, payload: any){
            let response = await Ajax.get('api/client-document-request/request-job?identifier=' + payload)
            return response.data.result as Array<ClientRequestDocument>;
        },
        async get(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let response = await Ajax.get('api/document-request/' + payload.data);
            return response.data.result as JobDocumentRequestVm;
        },
        async submitDocuments(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/client-document-request', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                vm.$Modal.success({title: 'Thanks for uploading files'})
            });
        },
        async previewDocument(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let response = await Ajax.get('api/document-request/document?id=' + payload, {responseType: 'blob'});
            let file = new Blob([response.data], {type: response.headers['content-type']});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },
        async applyDocument(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('api/document-request/apply-document?id=' + payload).then((response) => {
                vm.$Notice.success({title: 'Success'})
            });
        },
        async rejectDocument(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('api/document-request/reject-document?id=' + payload).then((response) => {
                vm.$Notice.success({title: 'Success'})
            });
        },
        async applyAllDocuments(context: ActionContext<DocumentRequestState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('api/document-request/apply-all-documents?id=' + payload).then((response) => {
                vm.$Notice.success({title: 'Success'})
            });
        }
    };
    mutations = {
        setCurrentPage(state: DocumentRequestState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: DocumentRequestState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: DocumentRequestState, documentRequest: JobDocumentRequestVm) {
            state.editDocumentRequest = documentRequest;
        }
    }
}

const documentRequestModule = new DocumentRequestModule();
export default documentRequestModule;
