import Entity from '../entity'

export default class AdminShippingOrder extends Entity<number> {
    readyDate: Date;
    contactId : number | null;
    notes: string;
    status: number;
    consigneeId: number;
    clientId: number;
    shippingMethod: number | null;
    hasHazardousGoods: boolean;
    incotermId: number | null;
    originId: number | null;
    destinationId: number | null;
    supplierId: number | null;
    loadingDate: Date;
    unloadingDate: Date;
    originAgentId: number | null;
    destinationAgentId: number | null;
    loadingAddressId: number | null;
    unloadingAddressId: number | null;
    loadingAddressNotes: string;
    unloadingAddressNotes: string;
    po: string;
    customSupplierName: string;
    customConsigneeName: string;
}
