import {Store, Module, ActionContext} from 'vuex'
import ListModule from '.././list-module'
import ListState from '.././list-state'
import Contact from '../../entities/companycontext/contact'
import ContactVm from '../../entities/companycontext/contactvm'
import Ajax from '../../../lib/ajax'
import ListMutations from '.././list-mutations'
import PageResult from '@/store/entities/page-result';
import Vue from "vue";
import LookUp from "@/store/entities/lookup";
import ContactLookUp from "@/store/entities/companycontext/contactlookup";


interface ContactState extends ListState<ContactVm> {
    editContact: Contact,
}

class ContactMutations extends ListMutations<Contact> {

}

class ContactModule extends ListModule<ContactState, any, ContactVm> {
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<ContactVm>(),
        loading: false,
        editContact: new Contact()
    }
    actions = {
        async getAll(context: ActionContext<ContactState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/contact/get', payload.data);
            context.state.loading = false;
            let page = response.data.result as PageResult<ContactVm>;
            context.state.totalCount = page.totalCount;
            context.state.list = page.items;
        },
        async create(context: ActionContext<ContactState, any>, payload: any) {
            await Ajax.post('/api/contact', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async update(context: ActionContext<ContactState, any>, payload: any) {
            await Ajax.put('/api/contact', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        async delete(context: ActionContext<ContactState, any>, payload: any) {
            await Ajax.delete('api/contact?Id=' + payload.data.id);
        },
        async get(context: ActionContext<ContactState, any>, payload: any) {
            let response = await Ajax.get('api/contact/' + payload.data);
            return response.data.result as Contact;
        },
        async registerUser(context: ActionContext<ContactState, any>, payload: any) {
            let vm = new Vue({});
            await Ajax.post('/api/contact/register', payload.data).then((response) => {
                vm.$Modal.success({title: 'Successfully created'})
            });
        },
        async lookUp(context: ActionContext<ContactState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/contact', {params: payload});
            return response.data.result as Array<ContactLookUp>;
        },
        async mailLookUp(context: ActionContext<ContactState, any>, payload: any) {
            let response = await Ajax.get('api/dictionary/mail-contacts', {params: payload});
            return response.data.result as Array<LookUp>;
        }
    };
    mutations = {
        setCurrentPage(state: ContactState, page: number) {
            state.currentPage = page;
        },
        setPageSize(state: ContactState, pagesize: number) {
            state.pageSize = pagesize;
        },
        edit(state: ContactState, contact: Contact) {
            state.editContact = contact;
        }
    }
}

const contactModule = new ContactModule();
export default contactModule;
