import Component from "vue-class-component";
import Vue from 'vue';
import App from './app.vue';
import iView from 'view-design';
import underscore from 'vue-underscore';
import {router} from './router/index';
import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';
import './theme.less';
import Ajax from './lib/ajax';
import Util from './lib/util';
import SignalRAspNetCoreHelper from './lib/SignalRAspNetCoreHelper';
import locale from 'view-design/dist/locale/en-US';

Vue.use(iView, {locale: locale}, underscore);
import store from './store/index';

Vue.config.productionTip = false;
import {appRouters, otherRouters} from './router/router';
import {UrlHelper} from "@/components/urlhelper";

const queryStringObj = UrlHelper.getQueryParameters();

if (queryStringObj.impersonationToken) {
    Ajax.post('/api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=' + queryStringObj.impersonationToken).then(data => {
        let result = data.data.result;
        abp.auth.setToken(result.accessToken);
        location.search = '';
    })
}

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
    let language = navigator.language;
    abp.utils.setCookieValue('Abp.Localization.CultureName', language, new Date(new Date().getTime() + 5 * 365 * 86400000), abp.appPath);
}

Ajax.get('/AbpUserConfiguration/GetAll').then(data => {
    Util.abp = Util.extend(true, Util.abp, data.data.result);
    new Vue({
        render: h => h(App),
        router: router,
        store: store,
        data: {
            currentPageName: ''
        },
        async mounted() {
            this.currentPageName = this.$route.name as string;
            await this.$store.dispatch({
                type: 'session/init'
            })
            if (!!this.$store.state.session.user && this.$store.state.session.application.features['SignalR']) {
                if (this.$store.state.session.application.features['SignalR.AspNetCore']) {
                    await SignalRAspNetCoreHelper.initSignalR();
                    // Util.abp.signalr.startConnection(abp.appPath + 'signalr-hitchHub', function (connection) {
                    //     connection.on('getMessage', function (message) {
                    //         console.log('received message: ' + message);
                    //     });
                    // })
                }
                this.$store.commit('app/initCachepage');
                this.$store.commit('app/updateMenulist');

                let that = this;
                abp.event.on('abp.notifications.received', function (args) {
                    if (args.notification.notificationName === 'Notification') {
                        that.$store.commit('app/addNotification', args.notification.data.properties['Message']);
                        let sound = require('@/assets/notification.mp3');
                        let note = new Audio(sound);
                        note.play();
                    }
                })
            }
        },
        created() {
            let tagsList: Array<any> = [];
            appRouters.map((item) => {
                if (item.children.length <= 1) {
                    tagsList.push(item.children[0]);
                } else {
                    tagsList.push(...item.children);
                }
            });
            this.$store.commit('app/setTagsList', tagsList);
        }
    }).$mount('#app')
})

